<template>
  <div v-resize="adjustSendSmsBottomAction">
    <v-alert
      dense
      outlined
      :value="error"
      dismissible
      type="error">
      Please review fields
    </v-alert>
    <v-card flat>
      <v-card-title
        class="title"
        primary-title
        v-if="interactiveMode === undefined || !interactiveMode"
      >
        <span>Send Text</span>
        <v-row justify="end">
          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
       <v-card-text class="pl-3 py-0 pr-0 hide-overflow">
        <v-row class="mr-0">
          <!-- <v-col class="pa-0" cols="3">
            <div class="label-div">
              <div class="pt-3">
                <span class="font-weight-medium body-2 ml-6">To</span>
              </div>
            </div>
          </v-col> -->
          <v-col class="pa-0 hb-text-night" cols="12">
            <hb-form label="Template" description="Select the Template">
                <HbAutocomplete v-model="templateName" :items="templateNames" v-validate="'required|max:45'" item-text='name'
              item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box" data-vv-as="Autocomplete Box"
              :error="errors.has('default.autocomplete-box')" placeholder="Select Template" class="mt-2 pl-1"
              @change="appendtemplate($event)" @click:clear="clearTemplate" />
              </hb-form>
            <hb-form label="To" required>
                <hb-combobox
                    :select="true"
                    v-model="selectedNumbers"
                    :items="allSMSNumbers"
                    :value="allSMSNumbers[0]"
                    v-validate.disable="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-as="To"
                    id="To"
                    name="To"
                    label="Select Contact"
                    flat
                ></hb-combobox>
                    <!-- :error="errors.collect('default.journey2').length > 0" -->
            </hb-form>
            <!-- <v-divider class="mt-1"></v-divider> -->
          </v-col>
          <v-col class= "pt-3 hb-text-night" cols="9">
            <!-- <div>{{to | formatPhone}}</div> -->
			<!-- <v-divider class="mt-1" style="width:180px;"></v-divider> -->
			<span style="color: rgba(0, 0, 0, 0.6);" >Select the space that this communication is in reference to. Select 'Tenant' if no space reference is required.</span>
      <space-selection-dropdown  :setSelection="true" :from="'send-sms'" :mainViewOptions="spaceListViewOptions" @spaceSelectionChanged="onSelectedSpace" @setDefaultSpaceValue="onSelectedSpace">
                        </space-selection-dropdown>
                 
            
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row ref="richTextEditor"  class="pr-3 rich-text-editor">
          <v-col class="pa-0" cols="12">
            <v-textarea
              solo
              dense
              flat
              hide-details
              background-color="#FFFFFF"
              auto-grow
              :style="`height:${richTextEditorHeight > 0 && usageContext ? richTextEditorHeight: textareaStyle}px`"
              v-model="Message"
              placeholder="Compose your personal message..."
              @focus="handleTextAreaFocus"
              ref="textAreaEditor"
            >
            </v-textarea>
            <!-- <div v-else style="max-height: 468px; min-height: 400px; overflow: auto; margin-top: 5px;">
              <div v-html="Message" style="font-size: medium;""></div>
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions ref="sendSmsActions" class="email-window-actions py-3">
        <!--        <v-btn icon @click="showFile = false"> <v-icon>mdi-paperclip</v-icon> </v-btn>-->
        <!--        <v-btn icon> <v-icon>mdi-plus</v-icon>  </v-btn>-->
<!--        <v-menu offset-y v-model="payNow">-->
<!--          <template v-slot:activator="{ on }">-->
<!--            <span v-on="on">-->
<!--              <hb-btn icon tooltip="Add" :active="payNow">mdi-plus</hb-btn>-->
<!--            </span>-->
<!--          </template>-->
<!--          <v-list>-->
<!--            <v-list-item @click="addPayNowLink">-->
<!--              <v-list-item-title>Pay Now Link</v-list-item-title>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->
            <hb-menu>
              <template v-slot:menu-activator>
                <hb-btn color="secondary" @click="handleAddMergeClick">
                   Add Merge Fields
                </hb-btn>
                <hb-btn icon @click="toggleList = true" mdi-code="mdi-paperclip" tooltip="Attach File"></hb-btn>
              </template>
              <v-list v-if="toggleList">
                <v-list-item class="pointer-cursor" @click="showFile = true,clearfiles = false">
                  Upload
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(0)" disabled>
                  Generate Documents (Non-Signature)
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(1)" disabled>
                  Generate Documents (Signature)
                </v-list-item>
              </v-list>
            </hb-menu>
        <hb-btn class="" icon tooltip="Include one-time payment link" mdi-code="mdi-currency-usd" 
        @click="addOneTimePaymentLink"></hb-btn>   

        <v-spacer></v-spacer>

        <hb-link class="mr-2" @click="onClose">Cancel</hb-link>
        <hb-btn class="mr-2" color="primary" :disabled="phoneId === undefined || phoneId === '' || this.allSMSNumbers.length == 0" @click="SendSMS" :loading="isLoading($options.name)" >Send</hb-btn>

      </v-card-actions>
    </v-card>
    <add-file :show="showFile" :onClose='onFileClose' :loading="fileLoading" :getAttachments="getAttachments" :clearData="clearfiles">
    </add-file>
  </div>

</template>

<script>
import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';
import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import MergeFields from "../includes/MergeFields";
import addFile from '../../components/communication_center/AddFile.vue'
import { stickBottomActionBarToBottom } from '../../utils/common.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name:'SendSMS',
  data () {
    return {
      richTextEditorHeight:0,
      templateName:'',
      templateNames:[],
      payNow: false,
      value: undefined,
      mask:'',
      Message: '',
	  Space:'',
      error:false,
      textareaStyle: '',
      spaceListView: {
        id: null,
        Unit: {
          number: 'Tenant',
          type: 'tenant',
        }
      },
      allSMSNumbers:[],
      selectedNumbers:[],
      pay_message: '',
      toggleList: false,
      showFile: false,
      attachments:[],
      fileLoading: false,
      clearfiles : false,
      debounceTimeout: null
    }
  },
  components:{SpaceSelectionDropdown,MergeFields,'add-file': addFile,},
  props: ['properties','to','phoneId','spaceListViewOptions','contactId','onClose','interactiveMode','textarea_height', 'onChatError', 'contactPhoneList','property_id','usageContext','smsWindowIdentifier'],
  computed: {
    ...mapGetters({
      triggerMergeFields: 'addMergeFieldsStore/triggerMergeFields',
      selectedMergeField: 'addMergeFieldsStore/selectedMergeField',
      actionSource: 'addMergeFieldsStore/actionSource',
      addMergeButtonClickFlag: 'addMergeFieldsStore/addMergeButtonClickFlag',
    })
  },
  mixins: [ notificationMixin ], 
    watch:{
    //    spaceListView: function (newValue) {
    //   // added to resolve duplicate bugs when chat window is closed and opened 
    //   this.$nextTick(()=>{
    //     this.onSelectedSpace(newValue)
    //   })
    // },
    selectedMergeField(newValue) {
        if (newValue) {
          this.insertMergeField(newValue)
        }
      }
  },
  created () {
    this.getAlternatePhone()
    this.selectedNumbers.push(this.allSMSNumbers[0])
    this.textareaStyle = this.usageContext ? 150 : this.$props.textarea_height + 48
    this.spaceListView =  this.spaceListViewOptions[1] ?? this.spaceListViewOptions[0]
    
  },
  beforeDestroy(){
      this.onChatError('')
      EventBus.$off('onInteraction')
  },
  async mounted(){ 
    await this.getBaseTemplateData()
    this.$nextTick(()=>{
     this.adjustSendSmsBottomAction()
    }) 
    EventBus.$on('onInteraction', this.adjustSendSmsBottomAction) 
  },
  methods: {
    ...mapActions({
        setTriggerMergeFields: 'addMergeFieldsStore/setTriggerMergeFields',
        setActionSource: 'addMergeFieldsStore/setActionSource',
        setFilterInput: "addMergeFieldsStore/setFilterInput",
        resetMergeFieldParameters: "addMergeFieldsStore/reset",
    }),
    triggerAddMergeFieldByInput(txt) {
      clearTimeout(this.debounceTimeout);
      const bracketMatch = /\[[^\]]+[^\]]*$/mg;
      const isMergeFlag = txt.replace(/<\/?[^>]+(>|$)/g, "").match(bracketMatch);
      const openBracketMatch = txt.match(/\[[^\]]+$/);
      this.debounceTimeout = setTimeout(() => {
        if (isMergeFlag) {
          if (openBracketMatch[0]) {
            this.setTriggerMergeFields({
              actionSource: this.smsWindowIdentifier,
              addMergeButtonClickFlag: false
            })
            this.setFilterInput({
              filterInput: openBracketMatch[0].replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9\s]/g, "")
            })
            
          }
        }
      }, 500)
    },
    insertMergeField(txt) {
      if (this.actionSource === this.smsWindowIdentifier) {
        if (this.addMergeButtonClickFlag) {
          const textarea = this.$refs.textAreaEditor.$el.querySelector('textarea');
          if (textarea) {
          const start = textarea.selectionStart;
          const end = textarea.selectionEnd;
          // Insert the text at the cursor position
          const before = this.Message.slice(0, start);
          const after = this.Message.slice(end);
          this.Message = before + txt + after;

            // Move the cursor to the end of the inserted text
            this.$nextTick(() => {
              textarea.setSelectionRange(start + txt.length, start + txt.length);
              textarea.focus();
            });
          } else {
            console.error("Textarea element not found!");
          }
          if (this.$vuetify.breakpoint.xs) {
            this.resetMergeFieldParameters()
          }
          this.setFilterInput({
            filterInput: ""
          })
          this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
        }
        else {
          const characterReplacedText =  this.Message.replace(/\[[^\]]+$/, txt);
          this.Message = characterReplacedText;
          if (this.$vuetify.breakpoint.xs) {
            this.resetMergeFieldParameters()
          }
          this.setFilterInput({
            filterInput: ""
          })
          this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
        }
      }
    },
    handleTextAreaFocus(){
      if(this.triggerMergeFields === true){
        this.setTriggerMergeFields({
        actionSource: this.smsWindowIdentifier
      })
      }      
    },
    handleAddMergeClick(){
      this.setTriggerMergeFields({
        actionSource: this.smsWindowIdentifier,
        addMergeButtonClickFlag: true
      })
    },
    adjustSendSmsBottomAction(){
      const sendSmsActions = this.$refs.sendSmsActions
      const richTextEditor = this.$refs.richTextEditor
      this.richTextEditorHeight = stickBottomActionBarToBottom(richTextEditor,sendSmsActions)
    },
    clearTemplate() {
      this.Message = ''
    },
    async getBaseTemplateData() {
        const templates = await this.getAllTemplatesWithId(this.properties);
        const combinedTemplates = [].concat(...templates);
        const templateFilterd =templates.filter(template => template.base_template_id === null)
        this.templateNames = templateFilterd.filter(template => template.type === 'sms').map(template => ({ name: template.name, template_id: template.template_id }));
        },
        async getAllTemplatesWithId(properties) {
            console.log(properties,"AMAR")
        const propertyIds = properties.map(property => property.id);
        return await this.getTemplatesById(propertyIds);
        },
        async getTemplatesById(propertyIds) {
            try {
                const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
                const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
                return response.templates;
            } catch (error) {
                console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
                throw error; // Rethrow the error to propagate it up
            }
        },
        async appendtemplate(id) {
        try {
            await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
              if (res.template.smsContent) {
              this.Message =  this.Message ?  this.Message + ' ' + res.template.smsContent : res.template.smsContent
            }
              else {
                this.showMessageNotification({ type: 'error', description: "Can't Attach Email Template to SMS Field" });
              }
            })
      } catch (error) {
        throw error;
      }
    },
      addPayNowLink(){
          this.Message += '\r\n[PayNow][/PayNow]';
      },
      addDirectionsLink(){
          //id is property_id
          this.Message += '\r\n[Directions|~id:YgBdxQlBwy]Click here get directions[/Directions]';
      },
	  onSelectedSpace(spacenumber) {
      console.log("the selected space is", spacenumber.Unit.number);
      console.log("spaceListView options is ",this.spaceListView.Unit.number);
      this.Space = spacenumber.Unit.number;
    },
    async addOneTimePaymentLink() {
      let data = {
        unit_number : this.Space,
        communication_type : 'Text'
      }
      let response = await api.get(this, api.CONTACTS + this.contactId + '/one-time-link', data);
        this.link = response.link;
        this.buttonHtml = this.link.length < 2048 ? this.link : `<a target="_blank" rel="noopener noreferrer nofollow" href="${this.link}"> Pay Now</a>`
        this.pay_message = `${this.buttonHtml}`
        this.Message += this.pay_message;
    },
    onFileClose() {
      this.showFile = false;
      this.toggleList = false;
    },
    async getAttachments(attachments) {
      try {
        this.fileLoading = true;
        this.attachments = attachments;
        if (this.attachments.length) {
          let fileUploadResponse = await this.upload(this.items);
          console.log(fileUploadResponse, "fileUploadResponse");

          let res = await api.get(this, api.SEND_SHORTEN_LINK + `?upload_id=${fileUploadResponse.uploads.id}&contact_id=${this.contactId}`);
          this.Message = this.Message ? this.Message + ' ' + res.short_url : res.short_url;
          this.fileLoading = false;
          this.attachments= [],
          this.clearfiles = true
          this.onFileClose();
        }
      } catch (error) {
        console.error("Error in getAttachments:", error);
        this.fileLoading = false;
        this.attachments= [],
        this.clearfiles = true
        this.onFileClose();
      
        // You can add any additional error handling logic here, such as showing an error message
      }
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      console.log("ATTACHMENTS", this.attachments)
      let response = await api.postFile(this, api.CONTACTS + 'uploads', body, this.attachments);

      return response

    },
    async SendSMS () {
      if (this.Message.trim() !== '' && this.selectedNumbers.length > 0) {
        this.phoneIds = this.getNumberIds()
        console.log('in send sms phoneId',this.phoneIds);
        var data = {
          message: this.Message,
          subject: ' ',
		      space: this.Space,
          send_email: false,
          sms: this.phoneIds,
          primary_contact_id: this.contactId,
          property_id:this.property_id
        }

        try {
          await Promise.all(this.phoneIds.map(item=>this.handleSendSms(data,item)))
          // await api.post(this, api.CONTACTS + this.contactId + '/send-message', data)
          this.onClose(true)
          EventBus.$emit('interaction_saved');
        } catch(err) {
          console.log('eererererer;',err);
          this.onChatError('The SMS attempted either does not exist, does not allow SMS texts, or is blocked by a networking issue.')
        }
      } else{
        this.error = true
      }

    },
	handleSendSms(data,contactObj){
      console.log('in send sms contactObj',contactObj,data);
      data.contact_type = contactObj.type
      data.sms =  [contactObj.id]
      return api.post(this, api.CONTACTS + contactObj.contact_id + '/send-message', data)

    },
    async getAlternatePhone(){
        this.allSMSNumbers = this.contactPhoneList.map(item => item.phone)
          console.log('in send sms allSMSNumbers, contactPhoneList',this.allSMSNumbers, this.contactPhoneList);
   
    },
    getNumberIds(){
      console.log('in send sms selectedNumbers contactPhoneList',this.selectedNumbers,this.contactPhoneList);
      return this.contactPhoneList.filter(item => this.selectedNumbers.indexOf(item.phone) !== -1)
    }

  }
}
</script>


<style scoped>
.rich-text-editor {
  overflow-y: auto;
}
.label-div {
  background-color: #F9FAFB;
  height: 128px;
  color: #101318;
}
.sms-input {
  height: 32px;
  color: #101318;
  border-bottom: 1px solid #C4CDD5;
  width: 275px;
}
.sms-input:focus {
   outline: none;
  border-bottom: 2px solid #00727A;
}
.hide-overflow {
  overflow: hidden;
}
</style>
